<template>
    <div>
        <v-app>
            <v-container fill-height class="center">
                <v-row justify="center" align="center">
                    <v-col cols="12" sm="12">
                        <h2>Registeration Complete</h2>
                        <br/>
                        <h4><b>Thank you for your registeration, you will recieve an e-mail once the registeration is
                                approved</b></h4><br/><br/>
                        <v-btn color="success" class="mr-0" @click="goHome">
                            Home
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-app>


    </div>
</template>
<script>
export default {
    data() {

    },
    methods: {
        goHome() {
            this.$store.dispatch("/");
            this.$router.push('/');
        }
    }
}
</script>
<style scoped>
.center {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background: #FFCEC2; */
}

.center br {
    width: 100%;
}
</style>